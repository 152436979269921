import React, { useEffect, useState } from "react";
import { useCallMethodMutation, useRefreshMutation } from "./authApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "./b24Slice";
import { Loader } from "../../components/Loader";
// import { useCallMethodQuery } from "./restApiSlice";

const ProfilePage = () => {
    const [name, setName] = useState('');
    const [avatar, setAvatar] = useState('');
    const [refresh, {isLoading}] = useRefreshMutation();
    const [ callMethod, {isSuccess} ] = useCallMethodMutation();
    const dispatch = useDispatch();


    useEffect(() => {
        callMethod({ method: 'profile' }).then(response => {
            console.log(response);
            setName(`${response?.data?.NAME} ${response?.data?.LAST_NAME}`);
            setAvatar(response?.data?.PERSONAL_PHOTO);
        });
    }, [callMethod])

    const handleClick = () => {
        console.log('refresh token ');
        refresh()
            .then(response =>{
                if (response?.data) {                    
                    dispatch(setCredentials({...response.data}));
                }
            });
        
    }

    if (isLoading)
        return <Loader />;

    if (isSuccess)
        return (
            <>
                <h1>Страница пользователя</h1>
                <p>{name}</p>
                <img src={avatar} />
            </>
        );
}

export default ProfilePage;