import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddLinkMutation } from './linksApiSlice';

export const CreatePage = () => {
    const navigate = useNavigate();
    const [link, setLink] = useState('');

    const [ addNewPost, {isLoading} ] = useAddLinkMutation();

    useEffect(() => {
        window.M.updateTextFields();
    }, []);

    const pressHandler = async event => {
        if (event.key === 'Enter') {
            try {
                const data = await addNewPost({ from: link }).unwrap();

                setLink('');
                navigate(`/links/${data.link._id}`); 
            } catch (error) {
                
            }
        }
    };

    return (
        <div className='row'>
            <div className='col s8 offset-s2' style={{padding: '2rem'}}>
                <div className="input-field">
                    <input 
                        placeholder="Вставьте ссылку" 
                        id="link" 
                        type="text"
                        onChange={e => setLink(e.target.value)}
                        value={link}
                        onKeyPress={pressHandler}
                        autoComplete="off"
                    />
                    <label htmlFor="link">Введите ссылку</label>
                </div>
            </div>
        </div>
        
    );
}