import { Loader } from '../../components/Loader';
import { LinksList } from './LinksList';
import { useGetLinksQuery } from './linksApiSlice';

export const LinksListPage = () => {
    const {
        data: links,
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetLinksQuery();

    if (isLoading) {
        return <Loader />;
    } else if (isSuccess) {
        return (
            <>
                {links && <LinksList links={links} />}
            </>
        );
    } else if (isError) {
        return (
            <p>{JSON.stringify(error)}</p>
        );
    }
}