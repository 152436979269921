import { b24apiSlice } from "./apiSlice";

export const authApiSlice = b24apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/token',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        refresh: builder.mutation({
            query: data => ({
                url: '/refresh',
                method: 'GET'  
            })
        }),
        callMethod: builder.mutation({
            query: data => ({
                url: `/rest/${data.method}`,
                method: 'POST'  
            })
        })
    }),
});

export const {
    useLoginMutation,
    useRefreshMutation,
    useCallMethodMutation
} = authApiSlice;