import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLoginMutation } from "./authApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "./b24Slice";

const SuccessPage = () => {
    const { accessToken } = useParams();
    const [login] = useLoginMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        login({ token: accessToken })
            .then(response => {
                if (response?.data) {                    
                    dispatch(setCredentials({...response.data}));
                    navigate('/b24/profile');
                }          
            });

    }, [dispatch, accessToken, login, navigate]);

    return (
        <div>Получаем ключи авторизации: {accessToken}</div>
    );
}

export default SuccessPage;