import React, { /*useContext,*/ useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useHttp } from '../hooks/http.hook';
// import { useMessage } from '../hooks/message.hook';
// import { AuthContext } from '../context/AuthContext';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from './authApiSlice';
import { setCredentials } from './authSlice';

export const AuthPage = () => {
    const userRef = useRef();
    const errRef = useRef();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const [login, { isLoading }] = useLoginMutation();
    const dispatch = useDispatch();

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const userData = await login({ mobile: user, password: pwd }).unwrap();
            dispatch(setCredentials({ ...userData, user }));
            setUser('');
            setPwd('');
            const fromPage = location.state?.from?.pathname || '/create';
            navigate(fromPage);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized')
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    const handleUserInput = (e) => setUser(e.target.value);
    const handlePwsInput = (e) => setPwd(e.target.value);

    // const auth = useContext(AuthContext);
    // const message = useMessage();
    // const navigate = useNavigate();
    // const { loading, request, error, clearError } = useHttp();
    // const location = useLocation();
    // const [form, stateForm] = useState({
    //     mobile: '', password: ''
    // });
    // const dispatch = useDispatch()

    // useEffect(() => {
    //     message(error);
    //     clearError();
    // }, [error, message, clearError]);

    // useEffect(() => {
    //     window.M.updateTextFields();
    // }, []);

    // const changeHandler = event => {
    //     stateForm({ ...form, [event.target.name]: event.target.value})
    // };

    // const registerHandler = async () => {
    //     try {
    //         const data = await request('/api/auth/registration', 'POST', {...form});
    //         message(data.message);
    //     } catch (error) {
            
    //     }
    // }

    // const loginHandler = async () => {
    //     try {
    //         const data = await request('/api/auth/login', 'POST', {...form});
    //         const res = dispatch({ type: 'LOG_IN', data });
    //         message(data.message?data.message:'Пользователь авторизован');
    //         auth.login(data.accessToken, data.user.id);
    //         const fromPage = location.state?.from?.pathname || '/create';
    //         console.log({ location })
    //         navigate(fromPage);
    //     } catch (error) {
            
    //     }
    // }

    

    return (
        <div className='row'>
            <div className='col s6 offset-s3'>
                <h1>Auth Page</h1>
                <p ref={errRef} className={errMsg ? "errMsg" : "offscreen"} >{errMsg}</p>
                {/* {fromPage} */}
                <div className="card blue darken-1">
                    <div className="card-content white-text">
                        <span className="card-title">Авторизация</span>
                        <div>
                            <div className="input-field">
                                <input 
                                    placeholder="Введите номер мобильного телефона" 
                                    id="mobile" 
                                    type="text"
                                    name='mobile'
                                    className='yellow-input'
                                    onChange={handleUserInput}
                                    value={user}
                                    autoComplete="off"
                                    ref={userRef}
                                />
                                <label htmlFor="mobile">Мобильный телефон</label>
                            </div>
                            <div className="input-field">
                                <input 
                                    placeholder="Введите пароль" 
                                    id="password" 
                                    type="password"
                                    name='password'
                                    className='yellow-input'
                                    onChange={handlePwsInput}
                                    value={pwd}
                                    autoComplete="off"
                                />
                                <label htmlFor="password">Пароль</label>
                            </div>
                        </div>
                    </div>
                    <div className="card-action">
                        <button 
                            className='btn yellow darken-4' 
                            style={{marginRight: 10}}
                            disabled={isLoading}
                            onClick={handleSubmit}
                        >
                            Войти
                        </button>
                        {/* <button 
                            className='btn grey lighten-1 black-text'
                            onClick={registerHandler}
                            disabled={loading}
                        >
                            Регистрация
                        </button> */}
                    </div>
                </div>
            </div>    
        </div>
    );
}