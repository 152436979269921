import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from './components/Layout';
import Public from './components/Public';
import RequireAuth from './features/auth/RequireAuth';
import Welcome from './features/auth/Welcome';
import { AuthPage } from './features/auth/AuthPage';
import { CreatePage } from './features/links/CreatePage';
import { DetailPage } from './features/links/DetailPage';
import { LinksListPage } from './features/links/LinksListPage';
import B24AuthPage from './features/b24/AuthPage';
import SuccessPage from './features/b24/SuccessPage';
import ProfilePage from './features/b24/ProfilePage';
import RequireB24Auth from './features/b24/RequireAuth';


import 'materialize-css';

function App() {

	return (			
		<Routes>
			<Route path='/' element={<Layout />} >
				{/* Public Routes */}
				<Route index element={<Public />} />
				<Route path='login' element={<AuthPage />} />

				{/* Protected Routes */}
				<Route element={<RequireAuth />} >
				
					<Route path='welcome' element={<Welcome />} />
					
					<Route path='links'>
						<Route index element={<LinksListPage />} />
						<Route path=':id' element={<DetailPage />} />
						<Route path='create' element={<CreatePage />} />
					</Route>						
					
				</Route>

				<Route path='b24'>
					<Route path='signin' element={<B24AuthPage />} /> 
					<Route path='success/:accessToken' element={<SuccessPage />} />
					<Route element={<RequireB24Auth />} >
						<Route path='profile' element={<ProfilePage />} /> 
					</Route>
					
				</Route>
				{/* <Route path='b24/success/:accessToken' element={<Welcome />} /> */}

				

				<Route path="*" element={<Navigate to="/" replace />}/>

			</Route>
		</Routes>
	);
}

export default App;
