import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { 
    persistStore, 
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { apiSlice } from './api/apiSlice';
import authReducer from '../features/auth/authSlice';
import counterReducer from '../features/counter/counterSlice';
import { localapiSlice } from '../features/localapi/localapiSlice';
import usersReducer from '../features/users/usersSlice';
import b24Reducer from '../features/b24/b24Slice';
import { b24apiSlice } from '../features/b24/apiSlice';

const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    [b24apiSlice.reducerPath]: b24apiSlice.reducer,
    [localapiSlice.reducerPath]: localapiSlice.reducer,
    auth: authReducer,
    b24: b24Reducer,
    counter: counterReducer,
    users: usersReducer,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['api', 'posts', 'localapi', 'b24api'],
    // whitelist: ['counter'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddlware => 
        getDefaultMiddlware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            }, 
        }).concat(apiSlice.middleware).concat(localapiSlice.middleware).concat(b24apiSlice.middleware),
    devTools: true
});

export const persistor = persistStore(store);