import { useParams } from 'react-router-dom';

import { Loader } from '../../components/Loader';
import { LinkCard } from './LinkCard';
import { useGetLinkByIdQuery } from './linksApiSlice';

export const DetailPage = () => {
    const linkId = useParams().id;

    const {
        data: link,
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetLinkByIdQuery(linkId);

    if (isLoading) {
        return <Loader />
    }

    if (isSuccess)
        return (
            
            <>
                {link && <LinkCard link={link} />}
            </>
        );

    if (isError)
            return <p>{error}</p>
}