import React from "react";
import { Link } from "react-router-dom";

export const LinksList = ({ links }) => {
    if (links.length === 0) {
        return <p className="center">Ссылок пока нет!</p>
    }
    return (
        <>
        <table>
            <thead>
            <tr>
                <th>№</th>
                <th>Оригинальная</th>
                <th>Сокрашенная</th>
                <th>Открыть</th>
            </tr>
            </thead>

            <tbody>
            {links.map((link, index) => 
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{link.from}</td>
                    <td>{link.to}</td>
                    <td>
                        <Link to={`/links/${link._id}`}>Открыть</Link>
                    </td> 
                </tr>
            )}
            </tbody>
        </table>
        </>
    );
}