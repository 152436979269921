import { NavLink} from "react-router-dom";
import { logOut } from "../features/auth/authSlice";
import { logOut as logOutB24 } from "../features/b24/b24Slice";
import { useDispatch } from "react-redux";

export const Navbar = () => {
    const dispatch = useDispatch();

    const logoutHandler = event => {
        event.preventDefault();
        dispatch(logOut());
        dispatch(logOutB24());
    };    

    return (
        <nav>
            <div className="nav-wrapper blue darken-1">
                <div className="container">
                    <span className="brand-logo">Сокращение ссылок</span>
                    <ul id="nav-mobile" className="right hide-on-med-and-down">
                        <li><NavLink to="/links/create">Создать</NavLink></li>
                        <li><NavLink to="/links">Ссылки</NavLink></li>
                        <li><a href="/" onClick={logoutHandler}>Выйти</a></li>
                    </ul>                    
                </div>
            </div>
        </nav>
    );
}