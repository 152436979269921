import { apiSlice } from "../../app/api/apiSlice";

export const linksApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLinks: builder.query({
            query: () => '/link',
            keepUnusedDataFor: 5
        }),
        getLinkById: builder.query({
            query: (id) => `/link/${id}`,
            keepUnusedDataFor: 0
        }),
        addLink: builder.mutation({
            query: initialLink => ({
                url: '/link/generate',
                method: 'POST',
                body: {
                    ...initialLink,
                    from: initialLink.from,
                }
            })
        })
    })
});

export const {
    useGetLinksQuery,
    useGetLinkByIdQuery,
    useAddLinkMutation
} = linksApiSlice;