import { Outlet } from "react-router-dom";

import { Navbar } from "./Navbar";

const Layout = () => {
    return (
        <>
            <Navbar />
            <div className='container' style={{paddingTop: 48, paddingBottom: 48}}>
                <Outlet />
            </div>   
        </>
    ); 
};

export default Layout;