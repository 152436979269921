import { createSlice } from '@reduxjs/toolkit';

const b24Slice = createSlice({
    name: 'b24',
    initialState: { user: null, token: null, memberId: null },
    reducers: {
        setCredentials: (state, action) => {
            const { user_id, access_token, member_id } = action.payload;
            state.user = user_id;
            state.token = access_token;
            state.memberId = member_id;
        },
        logOut: (state, action) => {
            state.user = null;
            state.token = null;
            state.memberId = null;
        }
    },
});

export const { setCredentials, logOut } = b24Slice.actions;

export default b24Slice.reducer;

export const selectCurrentUser = state => state.b24.user;
export const selectCurrentToken = state => state.b24.token;