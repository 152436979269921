const AuthPage = () => {    
    const hostname = document.location.hostname === 'localhost' 
        ? 'localhost:5000'
        : 'mern.soyuznik.online'
    const protocol = document.location.hostname === 'localhost' 
        ? 'http'
        : 'https'
    return (
        <div>
            <h1>Авторизоваться с использованием Битрикс24</h1>
            <a href={`${protocol}://${hostname}/b24/auth`}>Авторизоваться</a>
        </div>
    );
}

export default AuthPage;